import { useSelector } from 'react-redux'
import MainContent from './MainContent'
import SideDrawer from './SideDrawer'
import TopAppBar from './TopAppBar'
import { head, prop } from 'ramda'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'

const AppContent = () => {
  const theme = createMuiTheme({
    typography: {
      fontFamily: ['"Google Sans"', 'sans-serif'].join(',')
    }
  })

  const activeList = useSelector((state) => {
    const { lists, activeId } = state
    const active = lists.find((list) => list.id === activeId)
    if (active) return active
    return head(lists)
  })
  const lists = useSelector((state) => prop('lists', state))
  const active = useSelector((state) => prop('activeId', state))

  return (
    <ThemeProvider theme={theme}>
      <TopAppBar activeList={activeList} />
      <SideDrawer lists={lists} active={active || activeList.id} />
      <MainContent activeList={activeList} />
    </ThemeProvider>
  )
}

export default AppContent

import { Avatar } from '@material-ui/core'
import faker from 'faker'

const UserIcon = () => {
  return (
    <div style={{ marginLeft: '20px' }}>
      <Avatar alt="Some Person" src={faker.image.abstract()} />
    </div>
  )
}

export default UserIcon

import ListItem from './ListItem'
import { makeStyles } from '@material-ui/core/styles'
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreVert
} from '@material-ui/icons'
import { useState } from 'react'
import { IconButton } from '@material-ui/core'

const useStyles = makeStyles({
  completedItems: {
    backgroundColor: '#fafafa',

    '& .summary': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '64px',
      padding: '0px 16px',

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgb(238 238 238)'
      },

      '& .summaryIcon': {
        color: 'rgba(0, 0, 0, 0.54)',
        display: 'flex',
        alignItems: 'center',
        marginRight: '32px'
      },

      '& .summaryText': {
        flex: 1,
        color: 'rgba(0, 0, 0, 0.54)',
        fontFamily: 'Roboto, Helvetica-neue, sans-serif',
        fontSize: '15px',
        fontWeight: '500'
      }
    }
  }
})

const CompletedListItems = ({ items }) => {
  const classes = useStyles()

  const [visible, setVisible] = useState(false)

  return (
    <div className={classes.completedItems}>
      <div className="summary" onClick={() => setVisible(!visible)}>
        <div className={'summaryIcon'}>
          {visible ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </div>
        <div className="summaryText">
          <span>{items.length} checked off</span>
        </div>
        <div className="summaryActions">
          <IconButton>
            <MoreVert />
          </IconButton>
        </div>
      </div>
      {visible && (
        <div>
          {items.map((item) => (
            <ListItem key={item.id} item={item} />
          ))}
        </div>
      )}
    </div>
  )
}

export default CompletedListItems

import {
  assoc,
  prepend,
  assocPath,
  path,
  prop,
  update,
  remove,
  without
} from 'ramda'
import { generateLists } from './list-service'

const initialState = {
  menu: {
    open: false
  },
  activeId: undefined,
  primaryId: undefined,
  lists: generateLists()
}

const listByItem = (item, lists) => {
  for (let list of lists) {
    const { items } = list
    if (items.find((i) => i.id === item.id)) return list
  }
  return []
}

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'menu/toggle': {
      const currentStatus = path(['menu', 'open'], state)
      return assocPath(['menu', 'open'], !currentStatus, state)
    }
    case 'list/setActive': {
      const { payload } = action
      return assoc('activeId', prop('id', payload), state)
    }
    case 'list/create': {
      const { payload } = action
      console.log('creating a list', payload)
      const newLists = prepend(payload, prop('lists', state))
      return assoc('lists', newLists, state)
    }
    case 'list/update': {
      const { payload } = action
      console.log(`updating list ${payload.id}`, payload)
      const currentList = prop('lists', state).find((l) => l.id === payload.id)
      const currentListIndex = prop('lists', state).indexOf(currentList)
      console.log(`currentlistIndex ${currentListIndex}`)

      const updatedLists = update(currentListIndex, payload, state.lists)
      return assoc('lists', updatedLists, state)
    }
    case 'list/delete': {
      const { payload } = action
      return assoc('lists', without([payload], prop('lists', state)), state)
    }
    case 'list/item/create': {
      const { list, newItem } = prop('payload', action)
      console.log(`adding item ${newItem.value} to ${list.id}`)
      const listItems = prepend(newItem, list.items)
      const updatedList = assoc('items', listItems, list)
      console.log('updated list items', updatedList.items)
      const listToUpdateIndex = state.lists.indexOf(list)
      console.log(`list to update index ${listToUpdateIndex}`)
      const updatedLists = update(listToUpdateIndex, updatedList, state.lists)

      return assoc('lists', updatedLists, state)
    }
    case 'list/item/delete': {
      const { payload } = action
      // get correct list
      const list = listByItem(payload, state.lists)
      const listIndex = state.lists.indexOf(list)
      // get item
      const itemIndex = list.items.indexOf(payload)
      // update item
      const newItems = remove(itemIndex, 1, list.items)
      // updated list
      const updatedList = assoc('items', newItems, list)
      // update all lists
      const updatedLists = update(listIndex, updatedList, state.lists)
      return assoc('lists', updatedLists, state)
    }
    case 'list/item/update': {
      const { payload } = action
      // get correct list
      const list = listByItem(payload, state.lists)
      const listIndex = state.lists.indexOf(list)
      // get item
      const listItem = list.items.find((i) => i.id === payload.id)
      const listItemIndex = list.items.indexOf(listItem)
      // update item
      const newItems = update(listItemIndex, payload, list.items)
      // updated list
      const updatedList = assoc('items', newItems, list)
      // update all lists
      const updatedLists = update(listIndex, updatedList, state.lists)
      return assoc('lists', updatedLists, state)
    }
    default:
      return state
  }
}

import { assoc, prop } from 'ramda'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import { Add, Delete, Done } from '@material-ui/icons'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
  listItemActions: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
})

const ListItemActions = ({ item }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleDelete = (item) => {
    console.log(`deleting item ${prop('id', item)}`)
    dispatch({ type: 'list/item/delete', payload: item })
  }

  const handleDone = (item) => {
    console.log(`marking item ${prop('id', item)} done`)
    dispatch({
      type: 'list/item/update',
      payload: assoc('completed', Date.now(), item)
    })
  }

  const handleUndone = (item) => {
    console.log(`marking item ${prop('id', item)} undone`)
    dispatch({
      type: 'list/item/update',
      payload: assoc('completed', null, item)
    })
  }

  const DoneButton = () => (
    <IconButton onClick={() => handleDone(item)}>
      <Done />
    </IconButton>
  )

  const UndoneButton = () => (
    <IconButton onClick={() => handleUndone(item)}>
      <Add />
    </IconButton>
  )

  return (
    <div className={classes.listItemActions}>
      {prop('completed', item) ? <UndoneButton /> : <DoneButton />}
      <IconButton onClick={() => handleDelete(item)}>
        <Delete />
      </IconButton>
    </div>
  )
}

export default ListItemActions
